.button-with-login {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .btn {

    @include breakpoint(small only) {
      width: 100%;
    }

    @include breakpoint(medium up) {
      margin-right: 24px;
    }
  }

  .login-wrapper {
    padding-top: 15px;

    .link {
      margin-left: 5px;

      @media (max-width: 400px) {
        display: inline-block;
        margin-left: 0;
        margin-top: 3px;
      }
    }
  }
}