.section-faq {

  .accordion {
    margin: 16px 0 0;

    .accordion-title {
      cursor: pointer;
    }
  }
}
