/* BUTTONS
--------------------------------------------------------------------------
========================================================================*/

.btn {
  padding: 13px 24px;
  background: $btn-primary-bg-color;
  border-color: transparent;
  font-size: 18px;
  font-family: $nndagny-display-normal;;
  line-height: 1.22;

  &.bordered {
    border: 1px solid $btn-secondary-border-color !important;
    background-color: transparent;

    &:hover {
      border: 1px solid transparent !important;
    }
  }

  &:hover {
    background-color: $btn-hover-bg-color;
    border-color: transparent;
    color: $btn-hover-text-color;
  }

  &.hollow {
    padding: 13px 5px;
    border: 1px solid transparent;
    background: transparent;
    color: $color-gray;
    font-size: 16px;

    .chevron {
      position: relative;
      padding-left: 25px;

      &:before {
        //@include icon-css-mixin;
        //content: $icon-chevron-right;
        content: "";
        width: 7px;
        height: 14px;
        position: absolute;
        top: 4px;
        left: 0;
        //color: $color-orange;
        background-image: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-right.svg");
        background-size: cover;
        //font-size: 16px;
        transition: .2s;

        @include breakpoint(small only) {
          //left: -30px;
          font-size: 16px;
        }
      }
    }

    &:hover {
      color: $color-orange;

      .chevron {

        &:before {
          transform: translateX(4px);
        }
      }
    }
  }

  //#{$actions-types} {
  //  background: $color-maroon-light;
  //}
}

.link {
  color: $color-orange;
  font-size: 16px;
  font-family: $nndagny-display-normal;
  line-height: 1.25;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-orange;
    text-decoration: underline;
  }

  &:visited {
    color: $color-orange-dark !important;
  }
}
