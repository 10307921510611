.pagination {
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  //margin-top: 32px;

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin: 0 11px;
    background-color: #C4C4C4;
    border-radius: 10px;
    cursor: pointer;

    &-active {
      background-color: $color-orange;
    }
  }
}
