.main-content {
  position: relative;
  margin-bottom: 64px;

  @include breakpoint(medium down) {

    .sections {
      margin-top: 24px;
    }
  }

  @include breakpoint(large up) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    .side-nav {
      width: 27%;
      padding-top: 20px;
      height: auto;

      &.sticky {
        position: fixed;
      }

      &.bottom {
        position: absolute;
        bottom: 0;
        left: 0 !important;
      }
    }

    .sections {
      width: 73%;
      margin-top: 20px;
    }
  }
}

.section-header {
  margin-bottom: 16px;
  font-size: 28px;
  line-height: 1.28;
}

.sub-header {
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 1.4;
}

.callout {
  background: $color-white;
  border-radius: 3px;

  &.callout-section {
    margin-bottom: 24px;

    @include breakpoint(medium down) {
      padding: 24px;
    }

    @include breakpoint(large) {
      padding: 32px 48px;
    }
  }

  &.cut-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.cut-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }
}

.btn-wrapper  {

  @include breakpoint(large up) {
    display: flex;
  }

  .btn-group-item {

    @include breakpoint(small only) {
      margin-bottom: 16px;
    }

    &:nth-child(2) {

      @include breakpoint(medium up) {
        margin-left: 32px;
      }
    }

    &:nth-child(3) {
      display: inline-block;
      margin-left: auto;
      margin-right: 0;

      @include breakpoint(small only) {
        margin-bottom: 0;
      }
    }
  }
}

.text-orange {
  color: $color-orange;
}

.text-light-gray {
  color: $color-gray-light;
}

.bg-gray {
  background-color: $color-gray-bg;
}

.bg-white {
  background-color: $color-white;
}
