h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-grey-dark;
  font-family: $nndagny-display-normal;
}

.section-header {
  font-size: 28px;
  line-height: 1.28;
}

.section-header-small {
  font-size: 20px;
  line-height: 1.4;
}

.section-header-big {

  @include breakpoint(small only) {
    font-size: 32px;
    line-height: 1.25;
  }

  @include breakpoint(medium up) {
    font-size: 40px;
    line-height: 1.1;
  }
}

.section-header-text-size {
  font-size: 16px;
  line-height: 1.4;
}

[class*="section-header"] {
  &.color-orange {
    color: $color-orange-medium !important;
  }

  &.color-green {
    color: $color-green-700;
  }
}