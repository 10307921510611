.hero {
  border-radius: 3px;

  @include breakpoint(xlarge up) {
    width: calc(100% + 96px);
    margin-left: -48px;
  }

  & > .row {
    @media (min-width: 1200px) {
      max-width: 95.5rem;
      min-height: 420px;
      padding-right: 1.040625rem !important;
      padding-left: 1.040625rem !important;
    }

    @include breakpoint(large up) {
      // padding-top: 96px;
      // padding-bottom: 96px;
      background-image: var(--hero-bg);
      background-size: cover;
      background-position: center;
      border-radius: 3px;
    }

    @include breakpoint(xxlarge up) {
      min-height: 480px;
      // padding-top: 48px;
      // padding-bottom: 48px;
    }
  }

  .hero-image-mobile {
    @include breakpoint(medium down) {
      height: 240px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    @include breakpoint(medium only) {
      background-image: var(--hero-bg);
    }

    @include breakpoint(small down) {
      background-image: var(--hero-bg-mobile);
    }
  }

  .callout {
    @include breakpoint(medium down) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    @include breakpoint(large up) {
      margin-top: 96px;
      margin-bottom: 96px;
    }

    @include breakpoint(xlarge up) {
      margin-top: 48px;
      margin-bottom: 48px;
    }
  }
}
