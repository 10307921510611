.side-nav {
  //padding-left: 16px;

  .side-nav-header {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.33;
  }

  .nav-list {
    margin-bottom: 32px;
  }

  .side-nav-item.active {

    .nav-button {
      color: $color-orange !important;
      font-family: $nndagny-display-normal;
      text-decoration: none;

      &:after {
        display: block;
      }

      //.icon {
      //  color: $color-orange;
      //}

      svg {
        fill: $color-orange;
      }
    }
  }

  .nav-button {
    position: relative;
    display: block;
    padding: 10px 0 10px 32px;
    color: $color-gray !important;
    font-family: $nndagny-text-regular;
    font-size: 16px;
    line-height: 1.33;
    text-decoration: none;
    transition: 0.2s;

    &:after {
      display: none;
      content: "";
      width: 4px;
      height: 24px;
      position: absolute;
      top: 9px;
      right: 0;
      background: $color-orange;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    //.icon {
    //  position: absolute;
    //  top: 10px;
    //  left: 0;
    //  display: inline-block;
    //  margin-right: 10px;
    //  color: $color-gray;
    //  font-size: 18px;
    //  vertical-align: middle;
    //}

    svg {
      fill: $color-gray;
      position: absolute;
      top: 8px;
      left: 0;
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
      transform: scale(0.9);
    }

    &:hover {
      color: $color-orange !important;
      font-family: $nndagny-display-normal;
      text-decoration: none;

      &:after {
        display: block;
      }

      //.icon {
      //  color: $color-orange;
      //}

      svg {
        fill: $color-orange;
      }
    }
  }
}
