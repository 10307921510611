.superbrands-group {
 margin-top: 32px;
}

.why-details {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &:not(:last-of-type) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-gray-bg;
  }

  .icon-wrapper {
    width: 48px;
    min-width: 48px;
    height: 48px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 26px;
    background-color: $color-gray-bg;
    border-radius: 50%;

    &:before {
      //@include icon-css-mixin;
      //content: $icon-star;
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      bottom: -2px;
      right: -2px;
      //display: flex;
      //justify-content: center;
      //align-items: center;
      background: $color-orange-dark url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/star.svg") center no-repeat;
      //background-size: c;
      border-radius: 16px;
      //color: $color-white;

      //font-size: 10px;
    }

    //.icon {
    //  color: $color-gray;
    //  font-size: 26px;
    //}
  }

  .detail-header {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.25;

  }

  .detail-sub-header {
    margin-bottom: 0;
    color: $color-gray;
    font-size: 12px;
    line-height: 1.33;

    a {
      font-size: 12px;
    }
  }

  a {
    color: $color-orange;
    font-family: $nndagny-display-normal;
    line-height: 1.25;
    text-decoration: none;
  }
}
