.section-details {

  .section-header, .desc {
    margin-bottom: 24px;
  }

  .bullets-list {
    margin-top: 8px;
  }

  .description-icon-wrapper {
    margin-top: 24px;
    margin-bottom: 8px;
  }
}
