.sickness-table {
  background-color: $color-white;
  color: $color-gray;

  .columns:nth-of-type(3) {
    background-color: $color-orange-light;
  }

  .row {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .table-header {

    .columns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 16px;
      padding-bottom: 16px;
      text-align: center;
    }

    .plan-type {
      margin-bottom: 32px;
      font-family: $nndagny-display-normal;
      font-size: 16px;
      line-height: 1.25;
    }

    .plan-count {
      margin-bottom: 8px;
      font-family: $nndagny-text-regular;
      font-size: 20px;
      line-height: 1.4;
    }

    .plan-bottom {
      color: $color-gray-light;
      font-family: $nndagny-text-light;
      font-size: 16px;
      line-height: 1.25;
    }

    .plan-full {

      .plan-count {
        font-family: $nndagny-display-normal;
      }

      .plan-bottom {
        color: $color-gray;
      }
    }
  }

  .table-body {

    .row {

      .columns {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .columns:not(:first-of-type) {
        text-align: center;
      }
    }

    .accordion {

      .accordion-item {

        &:before {

          @include breakpoint(small only) {
            //left: 15px;
            //top: 49px;
            display: none;
          }

          @include breakpoint(medium up) {
            top: 48px;
            left: 24px;
          }
        }

        &.expanded {

          .accordion-title {

            &:before {
              transform: rotate(180deg);
            }

            .show-more {
              display: none;
            }
            .show-less {
              display: block;
            }
          }
        }
      }

      .accordion-title, .accordion-content {
        padding: 0;
      }

      .accordion-title {
        border-bottom: 2px solid $color-gray-border;
        cursor: pointer;

        &:before {
          @include breakpoint(small only) {
            content: "";
            width: 14px;
            height: 20px;
            position: absolute;
            bottom: 16px;
            left: 15px;
            background: transparent url(https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-down.svg) 50% no-repeat;
            background-size: contain;
            z-index: 1;
            transition: .2s;
            pointer-events: none;
          }
        }

        .row {

          .columns {

            @include breakpoint(small only) {
              padding-top: 16px;
              padding-bottom: 16px;
            }

            @include breakpoint(medium up) {
              padding-top: 48px;
              padding-bottom: 20px;
            }
          }
        }

        .sickness-name {
          display: block;

          @include breakpoint(small only) {
            padding-left: 0;
            font-family: $nndagny-text-regular;
          }

          @include breakpoint(medium up) {
            padding-left: 40px;
            font-family: $nndagny-display-normal;
          }
        }

        .more-button {
          display: block;
          margin-top: 12px;
          padding-left: 40px;
          font-family: $nndagny-display-normal;
          font-weight: bold;

          .show-less {
            display: none;
          }
        }
      }

      .accordion-content {

        .row {
          position: relative;

          &:after {
            content: "";
            height: 1px;
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: $color-gray-border;

            @include breakpoint(small only) {
              width: 100%;
            }

            @include breakpoint(medium up) {
              width: calc(100% - 16px);
            }
          }

        }

        .plan-wrapper {

          &.false {
            .included {
              display: none;
            }
          }

          &.true {
            .declined {
              display: none;
            }
          }
        }
      }
    }
  }
}

.modal-sickness-pow-zachorowanie {

  .modal-inner {

    @include breakpoint(small only) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @include breakpoint(large up) {
      padding-left: 100px;
      padding-right: 100px;
    }
  }
}
