.documents-list {

  .document {
    display: block;
    //width: 100%;
    border: 0;
    border-top: 1px solid $color-gray-bg;
    padding: 18px 5px 18px 32px;
    font-size: 16px;
    font-family: $nndagny-display-normal;
    line-height: 1.25;
  }

  li {
    position: relative;

    &:before {
      width: 24px;
      height: 24px;
      content: "";
      position: absolute;
      top: 16px;
      left: 2px;
      background: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/document-orange.svg");
      background-size: cover;
    }

    &:last-of-type {

      .document {
        border-bottom: 1px solid $color-gray-bg;
      }
    }
  }
}
