.video-player {
  margin-bottom: 32px;
}

.lazy-player {
  max-width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 3px;
  background: #000;
  overflow: hidden;
  z-index: 2;

  &:before {
    //@include icon-css-mixin;
    //content: $icon-play;
    content: "";
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    width: 48px;
    height: 48px;
    padding-left: 3px;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    border-radius: 50%;
    background: $color-orange url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/play.svg") center no-repeat;
    //color: $color-white;
    //font-size: 20px;
    transition: 200ms ease;
    text-align: center;
    pointer-events: none;
    z-index: 3;
  }

  &:hover {

    &:before {
      transform: scale(1.1);
      transition: 200ms ease;
    }
  }

  &.started {

    &:before {
      display: none;
    }
  }

  .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    background: $color-black var(--cover) no-repeat;
    background-size: cover;
    z-index: 2;
    cursor: pointer;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}



