.section-why {

  .section-header {
    margin-bottom: 24px;
  }

  .desc {
    margin-bottom: 32px;
  }
}
