.btn {
  width: auto;
  padding: 13px 24px;
  background: $color-btn-primary-bg;
  border-color: $color-btn-primary-text;
  font-size: 18px;
  font-family: $nndagny-display-normal;;
  line-height: 1.22;

  &.bordered {
    border: 1px solid $color-btn-secondary-border !important;
    background-color: transparent;

    &:focus {
      color: $color-btn-secondary-text;
    }
  }

  &:hover {
    background-color: $color-btn-hover-bg-color;
    border-color: $color-btn-hover-bg-color;
    color: $color-btn-hover-text-color;
    box-shadow: $btn-active-shadow;
  }

  &:focus {
    color: $color-white;
  }

  &.hollow {
    padding: 13px 5px;
    border: 1px solid transparent;
    background: transparent;
    color: $color-gray;
    font-size: 16px;

    .chevron {
      position: relative;
      padding-left: 25px;

      &:before {
        //@include icon-css-mixin;
        //content: $icon-chevron-right;
        content: "";
        width: 7px;
        height: 14px;
        position: absolute;
        top: 0;
        left: 0;
        //color: $color-orange;
        background-image: url("https://nn.pl/dam/ikony-przeformatowane/ikony-new/chevron-right.svg");
        background-size: cover;
        //font-size: 16px;
        transition: .2s;

        @include breakpoint(small only) {
          //left: -30px;
          font-size: 16px;
        }
      }
    }

    &:hover {
      color: $color-orange;

      .chevron {

        &:before {
          transform: translateX(4px);
        }
      }
    }
  }

  &:disabled {
    background: rgb(224, 224, 224);
    color: rgb(189, 189, 189);
    cursor: default;

    &:hover {
      border: transparent;
      box-shadow: none;
    }
  }

  //#{$actions-types} {
  //  background: $color-maroon-light;
  //}

  &.wide-300 {
    width: 300px;
  }

  &.small-expanded {    
    @include breakpoint(small only) {
      width: 100%;
    }
  }
}

.link {
  color: $color-orange;
  font-size: 16px;
  font-family: $nndagny-display-normal;
  line-height: 1;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-orange;
    text-decoration: underline;
  }

  &:visited {
    color: $color-orange-dark;
  }

  &.link-dark {
    color: $color-grey-dark !important;
    border-bottom: 2px solid $color-grey-dark;

    &:hover,
    &:focus {
      color: $color-orange !important;
      border-color: $color-orange !important;
      text-decoration: none;
    }
  }
  .btn-copy {
    color: #414141;
  }
  &.link-more {
    color: $color-grey-dark;
  }

  // &.arrowed {
  //   position: relative;
  //   padding-left: 1.25rem;
  
  //   &::before {
  //       content: "";
  //       font-family: icomoon !important;
  //       speak: none;
  //       font-style: normal;
  //       font-weight: 400;
  //       font-variant: normal;
  //       text-transform: none;
  //       line-height: 1;
  //       -webkit-font-smoothing: antialiased;
  //       -moz-osx-font-smoothing: grayscale;
  //       position: absolute;
  //       left: 0;
  //       top: 50%;
  //       color: $color-orange !important;
  //       transition: left .5s ease-in-out;
  //       line-height: 1;
  //       transform: translate(0, -50%);
  //   }
    
  //   &:visited {
  //     color: $color-grey-dark !important;
  //   }
    
  //   &:focus::before,
  //   &:hover::before {
  //       left: .375rem;
  //   }
  // }
}

