.callout {
  border-radius: 3px;
  background: $color-white;

  @include breakpoint(small only) {
    padding: 24px;
  }

  @include breakpoint(medium up) {
    padding: 32px 48px;
  }

  &.cut-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.cut-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.small-space {   
    @include breakpoint(medium up) {
      padding: 24px;
    }
  }

  @include bg-colors;
}