/* COLORS
--------------------------------------------------------------------------
========================================================================*/

$color-white:             #fff;
$color-black:             #000;
$color-gray:              #414141;
$color-gray-light:        #767676;
$color-gray-bg:           #F2F2F2;
$color-gray-border:       #E0E0E0;
$color-orange:            #EA650D;
$color-orange-dark:       #EE7F00;
$color-orange-light:      #FFF7EE;
$color-orange-hover:      #E64415;
$color-green:             #5AA896;

/* A11Y Buttons */
$btn-primary-bg-color: #404040;
$btn-primary-text-color: #fff;
$btn-secondary-bg-color: #fff;
$btn-secondary-border-color: #404040;
$btn-secondary-text-color: #404040;

$btn-hover-bg-color: #2B2B2B;
$btn-hover-text-color: #fff;
$btn-active-shadow: 0px 5px 20px 0px rgba(240, 128, 0, 0.15);

//DS
$color-grey-dark:         #414141;
$color-grey-medium:       #767676;
$color-orange-medium:     #EA650D;