.custom-modal {
  width: 100%;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  //overflow: hidden;
  z-index: 1005;
  overflow: scroll;

  .modal-inner {
    height: 100%;
    background-color: $color-white;
    transform: translateX(100%);
    transition: all .5s;
    overflow: scroll;

    @include breakpoint(medium down) {
      width: 100%;
      padding: 80px 30px 30px;
    }

    @include breakpoint(large) {
      width: 70vw;
      padding: 104px 250px;
      margin-left: auto;
    }

    &:before {
      content: "";
      width: 100%;
      left: 0;
      right: 0;
      position: absolute;
      border: 1px solid $color-gray-border;

      @include breakpoint(medium down) {
        top: 60px;
      }

      @include breakpoint(large) {
        top: 80px;
      }
    }

    .modal-header {
      color: $color-orange;
      font-family: $nndagny-display-normal;
      font-size: 40px;
      line-height: 1.1;

      @include breakpoint(medium down) {
        margin-bottom: 12px;
        font-size: 28px;
      }

      @include breakpoint(large) {
        margin-bottom: 32px;
        font-size: 40px;
      }
    }

    a {
      color: $color-orange;
      font-size: 16px;
      font-family: $nndagny-display-normal;
      line-height: 1.25;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $color-orange;
        text-decoration: underline;
      }

      &:visited {
        color: $color-orange-dark !important;
      }
    }
  }

  .close-modal {
    position: absolute;
    z-index: 1;

    @include breakpoint(medium down) {
      top: 20px;
      right: 20px;
    }

    @include breakpoint(large) {
      top: 33px;
      right: 53px;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  &.active {
    overflow: visible;
    display: block;
  }

  &.animated {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    transition: all 0.5s;

    .modal-inner {
      transform: translateX(0);
    }
  }
}
