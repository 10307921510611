/*! purgecss start ignore */

.nn-widget input[type=checkbox] {
  display: block;
}


// old form:
//@media (max-width: 1023px) {
//
//  .nn-widget__components-Pages-wrapper {
//    justify-content: flex-start !important;
//  }
//
//  .nn-widget__components-Form-horizontalForm {
//
//    .nn-widget__components-Checkbox-wrapper {
//
//      .nn-widget__components-Checkbox-checkbox + label {
//
//        &:before {
//          display: none;
//        }
//
//        &:after {
//          transition: none !important;
//        }
//      }
//
//      .nn-widget__components-Checkbox-checkbox:checked + label {
//
//        &:after {
//          top: 0;
//          transition: none !important;
//        }
//      }
//    }
//  }
//}
//
//@media (min-width: 1024px) {
//
//  .nn-widget {
//    position: relative;
//
//    input[type=checkbox] + label {
//
//      &:before {
//        content: none;
//      }
//
//      &:after {
//        margin: 0 !important;
//      }
//    }
//
//    .nn-widget__components-Pages-wrapper {
//      display: block;
//      padding-top: 40px;
//    }
//
//    .nn-widget__components-Pages-wrapper > div {
//      width: 100%;
//    }
//
//    .nn-widget__components-Form-horizontalForm {
//      min-width: 100%;
//
//      .nn-widget__components-Form-horizontalSubmit {
//        position: absolute;
//        left: 0;
//        bottom: -142px;
//      }
//    }
//
//    .nn-widget__components-Checkbox-wrapper {
//
//      label {
//        padding-left: 40px !important;
//      }
//    }
//
//    //step 2
//    // ta klasa występuje tylko w 2-gim kroku
//    .nn-widget__components-Pages-innerWrapper {
//
//      .nn-widget__components-Form-horizontalForm {
//
//        .nn-widget__components-Input-nnWidgetFormGroup:first-of-type {
//          max-width: 150px !important;
//          min-width: unset !important;
//        }
//
//        .nn-widget__components-Input-nnWidgetFormGroup:nth-of-type(2) {
//          max-width: 260px !important;
//          min-width: unset !important;
//        }
//
//        .nn-widget__components-Input-nnWidgetFormGroup:nth-of-type(3) {
//
//        }
//      }
//
//      .nn-widget__components-Select-components-DesktopSelect-nnSelectCheckboxWrapper {
//        top: 12px !important;
//      }
//
//      .nn-widget__components-Pages-skip-button.nn-widget__components-Pages-skipButtonHorizontal {
//        position: relative !important;
//        bottom: 0 !important;
//        margin-left: 30px;
//      }
//    }
//  }
//}

/*! purgecss end ignore */
