.section-hero {
  border-radius: 4px;

  @include breakpoint(medium down) {
    //margin-top: calc(117px + 24px);
  }

  @include breakpoint(large up) {
    width: calc(100% + 96px);
    margin-left: -48px;
  }

  & > .row {

    @include breakpoint(large up) {
      padding-top: 48px;
      padding-bottom: 48px;
      background-image: var(--hero-bg);
      background-size: cover;
      border-radius: 4px;
    }
  }

  .callout {

    @include breakpoint(medium down) {
      width: 100%;

      .bg-white {
        padding: 24px;
      }
    }

    @include breakpoint(small only) {
      padding: 240px 0 0;
      background: $color-orange var(--hero-bg-mobile) top/auto 60% no-repeat;
    }

    @include breakpoint(medium only) {
      padding: 300px 0 0;
      background: $color-orange var(--hero-bg) top/230% no-repeat;
    }

    @include breakpoint(large up) {
      margin-left: 48px;
      max-width: 550px;
      display: inline-block;
      padding: 24px 48px;
    }
  }

  .pre-header {

    span {
      display: inline-block;
      font-family: $nndagny-display-normal;

      &:first-of-type {
        margin-right: 16px;
      }

      &:nth-of-type(2) {
        margin-left: 16px;
      }
    }
  }

  .main-header, .main-sub-header {

    @include breakpoint(medium only) {
      max-width: 500px;
    }
  }

  .main-header {
    margin: 8px 0;
    font-size: 32px;
    line-height: 1.25;
  }

  .main-sub-header {
    margin-bottom: 24px;
  }

  .btn-hero {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 0;
    text-align: center;

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 11px;
      background-color: $color-gray-bg;
    }

    .arrow {
      position: relative;
      padding: 0 24px 0 56px;
      background: $color-white;
      font-size: 16px;
      line-height: 1.33;
      font-family: $nndagny-display-normal;
      z-index: 1;

      .svg-icon {
        position: absolute;
        top: -5px;
        left: 26px;
        transition: .2s;
      }
    }

    &:hover {

      .arrow {

        .svg-icon {
          transform: translateY(2px);
        }
      }
    }
  }
}


